loadSlider = (id, min, max, step, def) ->
	$("##{id}").slider
		value: parseFloat(def)
		min: parseFloat(min)
		max: parseFloat(max)
		step: parseFloat(step)
		slide: (event, ui) ->
			$("##{id}-val").val ui.value
	$("##{id}-val").val $("##{id}").slider('value')

jQuery(document).ready ($) ->
	$("#uid").on 'input', () ->
		$this = $ this
		val = this.value
		$this.addClass 'invalid'
		# $this[0].setCustomValidity('')
		# $this[0].reportValidity()
		if val.length < 3
			return
		$.get '/ajax.php?action=check_user&uid='+val, (data) ->
			if data is "OK"
				$this[0].setCustomValidity('')
				$this.removeClass 'invalid'
			else
				$this.addClass 'invalid'
		true

	form = document.forms[0]
	form.addEventListener 'submit', ((event) ->
		if $("#uid").hasClass 'invalid'
			$("#uid")[0].setCustomValidity('Введён неверный идентификатор!')
			# $("#uid")[0].reportValidity()
		else
			$("#uid")[0].setCustomValidity('')
				
		if form.checkValidity() == false
			event.preventDefault()
			event.stopPropagation()
			form.reportValidity()
		form.classList.add 'was-validated'
		return
	), false